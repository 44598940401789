@import '../../../../libs/wallex-core/src/styles/variables';
@import '../../../../libs/wallex-core/src/styles/mixins';

:root {
  --base-one: #fff;

  //backgrounds and borders
  --body-background: #111314;
  --item-border: #717377;
  --plate-border: #2b2d2d;
  --item-background: #242626;
  --item-disabled-background: #3b3d3d;
  --item-secondary-background: #2b2d2d;
  --dropdown-search-background: #2b2d2d;
  --section-border: #4e4f51;
  --main-text: #fff;
  --secondary-text: #9b9ea4;
  --border-secondary: #3b3d3d;
  --scrollbar-color: rgb(#{hex-to-rgb(#e2e3e7)} / 60%);
  --separator-color: #979797;
  --widget-border: #20b6b4;
  --safari-scroll-background: rgb(#{hex-to-rgb(#e2e3e7)} / 60%);

  //intercom color
  --intercom-color: #20b6b4;

  //menu text colors
  --menu-item: #20b6b4;
  --menu-item-hover: #fff;
  --menu-icon: brightness(0) saturate(100%) invert(50%) sepia(98%) saturate(354%) hue-rotate(130deg) brightness(99%) contrast(90%);
  --menu-icon-hover: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(263deg) brightness(100%) contrast(103%);
  --icon-inactive: #a7a7a7;

  //icons
  --icon-background: #20b6b4;
  --icon-color: var(--menu-icon-hover);
  --grey-text-icons: #767676;
  --icon-danger-background: #f9e6e6;
  --icon-danger-color: brightness(0) saturate(100%) invert(19%) sepia(95%) saturate(2326%) hue-rotate(346deg) brightness(97%) contrast(85%);
  --hover-filter: invert(61%) sepia(44%) saturate(761%) hue-rotate(130deg) brightness(90%) contrast(83%);

  //wallet types
  --fiat-type: #194d4d;
  --crypto-type: #20b6b4;
  --saving-type: #86e1e0;
  --market-chart: #20b6b4;

  //cards
  --card-text: #fff;
  --card-text-title: rgb(#{hex-to-rgb(#fff)} / 60%);
  --card-cvv: #111314;
  --logo-width: 12.9em; // only in em
  --fields-card: unset;
  --card-logo: unset;
  --card-background: var(--primary-gradient);

  // app colors
  --hint-background: #17302f;
  --notification-background: #17302f;
  --gradient-color1: #151515;
  --gradient-color2: #194e4e;
  --main-red: #cc3030;
  --grey: #373939;
  --grey-background: #2b2d2d;
  --grey-easy: #3e4043;
  --grey-primary: #888989;
  --light-red: #392424;
  --light-orange: #372d20;
  --dark-green: #205e3b;
  --text-green: #2aac66;
  --light-green: #1a312b;
  --light-blue: #20b6b4;
  --orange: #e17c05;
  --hover-primary: #229a98;
  --extra-light: #171f1f;
  --primary-text: #20b6b4;
  --button-primary: #20b6b4;
  --other-blue: #1e55b2;

  // gradients
  --primary-gradient: linear-gradient(136.32deg, var(--gradient-color1) 0%, var(--gradient-color2) 97.8%);

  // filters
  --to-light-blue: brightness(0) saturate(100%) invert(23%) sepia(81%) saturate(1452%) hue-rotate(202deg) brightness(100%) contrast(99%);
  --image-hover-filter: brightness(0) saturate(100%) invert(63%) sepia(24%) saturate(1351%) hue-rotate(130deg) brightness(91%) contrast(84%);
  --to-black: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7477%) hue-rotate(68deg) brightness(98%) contrast(107%);

  //sizes
  --auth-logo-height: 38px;
  --dashboard-logo-width: 151px;
  --dashboard-logo-mobile-width: 29.44px;

  //fonts
  --font-base-light: poppins-light;
  --font-base-regular: poppins-regular;
  --font-base-medium: poppins-medium;
  --font-base-bold: poppins-bold;
  --font-base-semiBold: poppins-semibold;
  --font-sans-regular: opensans-regular;
  --font-sans-semiBold: opensans-semibold;
  --font-sans-medium: opensans-medium;
  --font-kanit-light: kanit-light;
  --font-kanit-bold: kanit-bold;
}
